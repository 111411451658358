import React from 'react'

const Privacy = () => {
  return (
    <>
      <section className='py-5'>
        <div className='container'>
          <h4 className='text-dark-light'>1. INTRODUCTION</h4>
          <p className='text-light-dark'>Hude Production appreciates your business and trust. We are Cyprus based company, creating products to enhance your photography experience. Please read this Privacy Policy, providing consent to both documents in order to have permission to use our services.</p>


          <h4 className='text-dark-light mt-4 mb-4'>2. DATA COLLECTED</h4>
          <span className='text-white'>DATA STORAGE LOCATION</span>
          <p className='text-light-dark'>We are Cyprus based company and operate web servers hosted in Germany. Our hosting provider Hetzner Online GmbH adheres to the EU/US “Privacy Shield”, ensuring that your data is securely stored and GDPR compliant. For more information on Hetzner Online GmbH privacy policy, please see here: Hetzner Data Privacy Policy.</p>

          <span className='text-white'>REGISTRATION DATA</span>
          <p className='text-light-dark'>If you register on our website, we store your chosen username and your email address and any additional personal information added to your user profile. You can see, edit, or delete your personal information at any time (except changing your username). Website administrators can also see and edit this information.</p>

          <span className='text-white'>PURCHASE DATA</span>
          <p className='text-light-dark'>To receive product support, you have to have one or more Envato/AxiomThemes purchase codes on our website. These purchase codes will be stored together with support expiration dates and your user data. This is required for us to provide you with downloads, product support and other customer services.</p>

          <span className='text-white'>SUPPORT DATA</span>
          <p className='text-light-dark'>If you have registered on our website and have a valid support account, you can submit support tickets for assistance. Support form submissions are sent to our third party Ticksy ticketing system. Only the data you explicitly provided is sent, and you are asked for consent, each time you want to create a new support ticket. Ticksy adheres to the EU/US “Privacy Shield” and you can see their privacy policy here: Ticksy Privacy Policy.</p>

          <span className='text-white'>COMMENTS</span>
          <p className='text-light-dark'>When you leave comments on the website we collect the data shown in the comments form, and also the IP address and browser user agent string to help spam detection.</p>

          <span className='text-white'>CONTACT FORM</span>
          <p className='text-light-dark'>Information submitted through the contact form on our site is sent to our company email, hosted by Zoho. Zoho adheres to the EU/US “Privacy Shield” policy and you can find more information about this here: Zoho Privacy Policy.</p>
          <small>These submissions are only kept for customer service purposes they are never used for marketing purposes or shared with third parties.</small>
          <span className='text-white'>GOOGLE ANALYTICS</span>
          <p className='text-light-dark'>We use Google Analytics on our site for anonymous reporting of site usage. So, no personalized data is stored. If you would like to opt-out of Google Analytics monitoring your behavior on our website please use this link: Google Analytics Opt-out.</p>

          <span className='text-white'>CASES FOR USING THE PERSONAL DATA</span><br></br>
          <b className='text-light-dark'>We use your personal information in the following cases:</b>
          <ul>
            <li className='text-light-dark'>Verification/identification of the user during website usage;</li>
            <li className='text-light-dark'>Providing Technical Assistance;</li>
            <li className='text-light-dark'>Sending updates to our users with important information to inform about news/changes;</li>
            <li className='text-light-dark'>Checking the accounts’ activity in order to prevent fraudulent transactions and ensure the security</li>
            <li className='text-light-dark'>over our customers’ personal information;</li>
            <li className='text-light-dark'>Customize the website to make your experience more personal and engaging;</li>
            <li className='text-light-dark'>Guarantee overall performance and administrative functions run smoothly.</li>
          </ul>


          <h4 className='text-dark-light mt-4 mb-4'>3. EMBEDDED CONTENT</h4>
          <p className='text-light-dark'>Pages on this site may include embedded content, like YouTube videos, for example. Embedded content from other websites behaves in the exact same way as if you visited the other website. These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged-in to that website. Below you can find a list of the services we use:</p>

          <span className='text-white'>FACEBOOK</span>
          <p className='text-light-dark'>The Facebook page plugin is used to display our Facebook timeline on our site. Facebook has its own cookie and privacy policies over which we have no control. There is no installation of cookies from Facebook and your IP is not sent to a Facebook server until you consent to it. See their privacy policy here: Facebook Privacy Policy .</p>


          <span className='text-white'>TWITTER</span>
          <p className='text-light-dark'>We use the Twitter API to display our tweets timeline on our site. Twitter has its own cookie and privacy policies over which we have no control. Your IP is not sent to a Twitter server until you consent to it. See their privacy policy here: Twitter Privacy Policy .</p>

          <span className='text-white'>YOUTUBE</span>
          <p className='text-light-dark'>We use YouTube videos embedded on our site. YouTube has its own cookie and privacy policies over which we have no control. There is no installation of cookies from YouTube and your IP is not sent to a YouTube server until you consent to it. See their privacy policy here: YouTube Privacy Policy.</p>


          <span className='text-white'></span>
          <p className='text-light-dark'></p>


          <span className='text-white'></span>
          <p className='text-light-dark'></p>


          <span className='text-white'></span>
          <p className='text-light-dark'></p>





        </div>
      </section>
    </>
  )
}

export default Privacy;
