import React, { useState } from 'react'
import { useParams } from 'react-router-dom';

import aboutMeBg from '../reassets/backgrounds/bg-about-me.jpg';
import ProjectGallery from '../components/common/ProjectGallery';
import EnquiryModal from '../components/modal/EnquiryModal';

//wedding
import weddingImg1 from '../reassets/Images/wedding/1.webp';
import weddingImg2 from '../reassets/Images/wedding/2.webp';
import weddingImg3 from '../reassets/Images/wedding/3.webp';


//prewedding
import preweddingImg1 from '../reassets/Images/pre-wedding/1.webp';
import preweddingImg2 from '../reassets/Images/pre-wedding/2.webp';
import preweddingImg3 from '../reassets/Images/pre-wedding/3.webp';


import fashionImg1 from '../reassets/Images/fashion/RANA0006.webp';
import fashionImg2 from '../reassets/Images/fashion/RANA0425-cropped.jpg';
import fashionImg3 from '../reassets/Images/fashion/RANA0227.webp';

import productImg1 from '../reassets/Images/product/RANA0416.webp';
import productImg2 from '../reassets/Images/product/RANA0350-cropped.jpg';
import productImg3 from '../reassets/Images/product/RANA0001.webp';

import ecommImg1 from '../reassets/Images/ecommerce/3K8A7731.webp';
import ecommImg2 from '../reassets/Images/ecommerce/_K8A8786.webp';
import ecommImg3 from '../reassets/Images/ecommerce/_K8A8702.webp';






const Servicedetails = () => {

  let { service_name } = useParams();
  const [show, setShow] = useState(false);
  const toggleModal = () => {
    show === true ? setShow(false) : setShow(true);
  }

  const getImageItems = () => {
    const weddingImages = [

      weddingImg1,
      weddingImg2,
      weddingImg3,

    ];

    const preWeddingImages = [

      preweddingImg1,
      preweddingImg2,
      preweddingImg3,
    ];

    const fashionImages = [
      fashionImg1,
      fashionImg2,
      fashionImg3,

    ];

    const ecommImages = [

      ecommImg1,
      ecommImg2,
      ecommImg3,

    ];

    const productImages = [

      productImg1,
      productImg2,
      productImg3,

    ];

    const allImages = [
      fashionImg1,
      fashionImg2,
      fashionImg3,
      productImg1,
      productImg2,
      productImg3,
      ecommImg1,
      ecommImg2,
      ecommImg3,
      weddingImg1,
      weddingImg2,
      weddingImg3,
      preweddingImg1,
      preweddingImg2,
      preweddingImg3,
    ];


    if (service_name === 'wedding') {
      return weddingImages;
    }
    else if (service_name === 'pre-wedding') {
      return preWeddingImages;
    }
    else if (service_name === 'e-commerce') {
      return ecommImages;
    }
    else if (service_name === 'fashion') {
      return fashionImages;
    }
    else if (service_name === 'product') {
      return productImages;
    }
    else if (service_name === 'editing') {
      return;
    } else {
      return allImages;
    }
  }



  return (
    <>

      <section className='service-details' style={{ background: `url(${aboutMeBg})`, height: 'auto', backgroundSize: 'cover', backgroundPosition: 'center -260px' }}>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-5 py-5'>
              <small className='text-white'>service</small>
              <h4 className='text-white'>{service_name?.toUpperCase()}</h4>
              <p className='text-white'>{""}</p>
            </div>
            <div className='col-md-7'>
              <div className='float-right'>
                <span>Starting From</span>
                <p>₹ XX,XXX</p>
                <button className='btn btn-outline-light' onClick={toggleModal}>Book Now</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectGallery images={getImageItems()} mediaOnly={true} />
      <EnquiryModal showModal={show} handleToggleModal={toggleModal} />

    </>
  )
}

export default Servicedetails;
