import React, { useState } from 'react';
import { Link } from 'react-router-dom';


import cameraBg from '../reassets/backgrounds/camera-bg.jpg';

import EnquiryModal from '../components/modal/EnquiryModal';

import weddingImg1 from '../reassets/Images/wedding/1.webp';
import weddingOrnamentsImg from '../reassets/Images/service/wedding-ornaments.png';
import preWeddingImg from '../reassets/Images/service/pre-wedding.webp';
import fashionImg from '../reassets/Images/fashion/RANA0248-cropped.jpg';
import productImg from '../reassets/Images/product/RANA0101.webp';
import ecommImg from '../reassets/Images/ecommerce/_K8A8550-cropped.jpg';
import cameraImg from '../reassets/Images/camera.jpg';





const Services = () => {

  const [show, setShow] = useState(false);
  const toggleModal = () => {
    show === true ? setShow(false) : setShow(true);
  }

  const services = [
    {
      title: "Wedding",
      startingPrice: "xx,xxx",
      titleImg: weddingImg1,
      features: [
        "6-hour coverage",
        "Online proofing and ordering",
        "16x20 Art Print",
        "Custom 24 page album",
        "SD-card with Hi-res photos"

      ],
      pageLink: '/services/wedding',

    },
    {
      title: "Pre Wedding",
      startingPrice: "xx,xxx",
      titleImg: preWeddingImg,
      features: [
        "6-hour coverage",
        "Online proofing and ordering",
        "16x20 Art Print",
        "Custom 24 page album",
        "SD-card with Hi-res photos"

      ],
      pageLink: '/services/pre-wedding',

    },
    {
      title: "Fashion",
      startingPrice: "xx,xxx",
      titleImg: fashionImg,
      features: [
        "6-hour coverage",
        "Online proofing and ordering",
        "16x20 Art Print",
        "Custom 24 page album",
        "SD-card with Hi-res photos"

      ],
      pageLink: '/services/fashion',

    },
    {
      title: "Product",
      startingPrice: "xx,xxx",
      titleImg: productImg,
      features: [
        "6-hour coverage",
        "Online proofing and ordering",
        "16x20 Art Print",
        "Custom 24 page album",
        "SD-card with Hi-res photos"

      ],
      pageLink: '/services/product',

    },
    {
      title: "E-Commerce",
      startingPrice: "xx,xxx",
      titleImg: ecommImg,
      features: [
        "6-hour coverage",
        "Online proofing and ordering",
        "16x20 Art Print",
        "Custom 24 page album",
        "SD-card with Hi-res photos"

      ],
      pageLink: '/services/e-commerce',

    },
    {
      title: "Editing",
      startingPrice: "xx,xxx",
      titleImg: cameraImg,
      features: [
        "6-hour coverage",
        "Online proofing and ordering",
        "16x20 Art Print",
        "Custom 24 page album",
        "SD-card with Hi-res photos"

      ],
      pageLink: '/services/editing',

    }
  ]


  return (
    <>
      <section className="about-us-hude py-4" style={{ background: `url(${cameraBg})`, height: 'auto', backgroundSize: 'cover', backgroundPosition: 'center -260px' }}>
        <div className="page-header-content text-center">
          <div className="container">
            <h1 className="heading">
              Services <span className="icon"><i className="fa fa-camera"></i></span>
            </h1>
            <p className='mb-0'>Lorem ipsum dolor sit amet consectetur adipiscing elit sed bibendum leo. Mauris <br /> massa eleifend et purus vel feugiat rutrum nulla cras vitae est.</p>
          </div>
        </div>
      </section>
      <section className='service-wrapper-new'>
        <div className='container mt-5'>
          {
            services && services.map(service => (
              <div className='row mb-5'>
                <div className='col-md-8'>
                  <div className='bg-dark-img'>
                    <img src={service.titleImg} alt="" width={'100%'} />
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='card'>
                    <img src={weddingOrnamentsImg} alt="" width="100%" />
                    <div className='card-body'>
                      <h4 className='text-white mb-4'>{service.title}</h4>

                      <small className='text-white mt-5'>Starting From</small>
                      <h1 className='text-white fw-bolder mb-5'>₹ {service.startingPrice}</h1>
                      <h5 className='text-white mb-4'>What is include</h5>
                      <ul>
                        {
                          service.features.map(feature => (
                            <li>{feature}</li>
                          ))
                        }

                      </ul>
                      <div className='service-button'>
                        <button className='btn btn-light' onClick={toggleModal}>Get Quote</button>
                        <Link className='btn btn-light' to={service.pageLink}>Explore</Link>
                        <button type='buttom' className='btn btn-dark' onClick={toggleModal}>Book Now</button>
                      </div>


                    </div>
                  </div>
                </div>

              </div>
            ))
          }

        </div>
      </section>

      <EnquiryModal showModal={show} handleToggleModal={toggleModal} />


    </>

  )
}

export default Services;
