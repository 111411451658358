import React from 'react'

const footer = () => {
  return (
    <>
    <section className='py-4 bg-dark'>
       <div className='container'>
        <div className='row'>
        <div className='col-md-4'>
          <h2 className='text-white'>Say hi!</h2>
          <p className='text-white'>The world without photography will be meaningless to us if there is no light and color, which opens up our minds and expresses passion.</p>
        </div>
        <div className='col-md-4'>
        <h2 className='text-white'>Latest Photos</h2>

        </div>
        <div className='col-md-4'>
        <h2 className='text-white'>Contact</h2>
        <p className='text-white'><span>Phone : +91 9818701404</span></p>
        <p className='text-white'><span>Email : info@hudeproduction.com</span></p>

        <small className='text-white'>Copyright © 2023 Hude Production. All Rights Reserved.</small>
        </div>
        </div>
       </div>
    </section>



    </>
  )
}

export default footer
