import './App.css';
import { Routes, Route } from 'react-router-dom';
import React, { useState } from "react";
import Layout from './layout/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Privacy from './pages/Privacy';
import ServiceDetails from './pages/Servicedetails';
import { Toaster } from 'react-hot-toast';



function App() {

  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/" element={<Layout />}>

          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="services/:service_name" element={<ServiceDetails />} />
          <Route path="privacy" element={<Privacy />} />

        </Route>
      </Routes>
    </>
  );
}

export default App;
