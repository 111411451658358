import React from 'react'
import { Link } from 'react-router-dom';

import aboutMeBg from '../reassets/backgrounds/bg-about-me.jpg';

import customerServiceIcon from '../reassets/Images/icons/customer-service.png';
import reporterServiceIcon from '../reassets/Images/icons/reporter.png';
import videoServiceIcon from '../reassets/Images/icons/video-camera.png';

import filmIcon from '../reassets/Images/icons/film.png';
import lensIcon from '../reassets/Images/icons/lens.png';
import cameraIcon from '../reassets/Images/icons/camera.png';



const About = () => {
    return (
        <>
            <section className="about-us-hude py-4" style={{ background: `url(${aboutMeBg})`, height: 'auto', backgroundSize: 'cover', backgroundPosition: 'center -260px' }}>
                <div className="page-header-content text-center">
                    <div className="container">
                        <h1 className="heading">
                            About Us <span className="icon"><i className="fa fa-camera"></i></span>
                        </h1>
                        <p className='mb-0'>Lorem ipsum dolor sit amet consectetur adipiscing elit sed bibendum leo. Mauris <br /> massa eleifend et purus vel feugiat rutrum nulla cras vitae est.</p>
                    </div>
                </div>
            </section>

            <section className='py-5 about-info-wrapper' style={{ background: "#000" }}>
                <div className='container'>
                    <div className='row'>
                        {/* <div className='col-md-6'>
                            <img src={weddingImg1} alt='' width="100%" />
                        </div>
                        <div className='col-md-6'>
                            <img src={weddingImg5} alt='' width="100%" style={{ height: '510px', objectFit: 'cover' }} />
                        </div> */}
                        <div className='col-md-12'>
                            <div className='card bg-dark p-5'>
                                <div>
                                    <h1 className='text-white'>25 Years of Experience</h1>
                                    <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed bibe ndum leo maur massa eleifend et purus vel feugiat rutrum nulla ras vitae est velpsbus fermentum a ultricies urna cum sociis ut valley et. Lorem ipsum dolor sit amet, consectetur adipiscing eli Ut sed biben dum leo maur massa eleifend .</p>
                                </div>
                                <h1 className='text-white'>Our Amazing Features</h1>
                                <ul>
                                    <li className='d-flex gap-3'>
                                        <img src={customerServiceIcon} alt='' style={{ height: '40px' }} />

                                        <div>
                                            <h4 className='text-white'>HAPPY CLIENT</h4>
                                            <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed bibendum leo.</p>
                                        </div>
                                    </li>
                                    <li className='d-flex gap-3'>
                                        <img src={reporterServiceIcon} alt='' style={{ height: '40px' }} />

                                        <div>
                                            <h4 className='text-white'>EXPERT PHOTOGRAPHY</h4>
                                            <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed bibendum leo.</p>
                                        </div>
                                    </li>
                                    <li className='d-flex gap-3'>
                                        <img src={videoServiceIcon} alt='' style={{ height: '40px' }} />


                                        <div>
                                            <h4 className='text-white'>CINEMATOGRAPHY</h4>
                                            <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed bibendum leo.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='bg-dark py-5' >
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='card text-center p-5' style={{ background: "#000" }}>
                                <i className='mb-3'><img src={filmIcon} alt='' /></i>
                                <h5 className='text-white mb-3'>SHORT FILM PRODUCTION</h5>
                                <p className='text-white'>Vestibulum id dapibus ante, in feugiat nisl. Etiam id lacus et tellus consequat tempus.consectetur adipiscing eli Ut sed biben dum leo maur massa eleifend .</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card text-center p-5' style={{ background: "#000" }}>
                                <i className='mb-3'><img src={lensIcon} alt='' /></i>
                                <h5 className='text-white mb-3'>VIDEO PRESENTATION</h5>
                                <p className='text-white'>Vestibulum id dapibus ante, in feugiat nisl. Etiam id lacus et tellus consequat tempus.consectetur adipiscing eli Ut sed biben dum leo maur massa eleifend .</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card text-center p-5' style={{ background: "#000" }}>
                                <i className='mb-3'><img src={cameraIcon} alt='' /></i>
                                <h5 className='text-white mb-3'>ADVERTISEMENT MAKER</h5>
                                <p className='text-white'>Vestibulum id dapibus ante, in feugiat nisl. Etiam id lacus et tellus consequat tempus.consectetur adipiscing eli Ut sed biben dum leo maur massa eleifend .</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <sction className="bg-dark" style={{ background: "#000" }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 p-5'>
                            <p className='paragraph-quote'>"It is my intention to present - through the medium of photography - intuitive observations of the natural world which may have meaning to the spectators."</p>
                            <br />

                        </div>
                        <div className='col-md-6'></div>
                    </div>
                </div>
            </sction>
        </>
    )
}

export default About;
