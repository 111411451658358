import React from 'react'
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";


const Layout = () => {
    return (
        <>
            <div id='wrapper'>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header />
                        <Outlet />
                        <Footer />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Layout
