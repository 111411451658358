import React, { useState } from 'react';
import "./modal.css";
import { sendMail } from "../../helper/commonHelper";
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';


const EnquiryModal = (props) => {

  const [formInfo, setFormInfo] = useState({
    name: "",
    email: "",
    mobile: "",
    remarks: "I would like to enquire about ",

  })

  const handleChange = (e) => {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handle submit", formInfo);
    // toast.loading("waiting...")
    sendMail(formInfo);
    toast.success("Your Enquiry Has Been Submitted...");
    props?.handleToggleModal();
  }

  return (
    <Modal show={props?.showModal} onHide={props?.handleToggleModal}>
      <div className="modal-content">
        <div className="modal-body ">
          <Button type="button" className="btn-close text-light" style={{ position: "absolute", right: "10px", top: "10px" }} onClick={props?.handleToggleModal}></Button>
          <Form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-md-12'>
                <div className='col-md-12 text-center'>
                  <h4 className='text-white'>Enquiry Form</h4>
                  <p className='text-white'>We Will Get In Touch With You Shortly</p>
                </div>
                <div className='col-md-12 mb-3'>
                  <input type='text' name="name" placeholder='Name' className='form-control' onChange={handleChange} />
                </div>
                <div className='col-md-12 mb-3'>
                  <input type='text' name="email" placeholder='Email' className='form-control' onChange={handleChange} />
                </div>
                <div className='col-md-12 mb-3'>
                  <input type='text' name="mobile" placeholder='Mobile' className='form-control' onChange={handleChange} />
                </div>
                <div className='col-md-12 mb-3'>
                  <textarea type='text' name="remarks" placeholder='Message' className='form-control' onChange={handleChange} value={formInfo.remarks || ""}></textarea>
                </div>
                <div className='col-md-3'>
                  <Button variant="success" type="submit">
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>

      </div>
    </Modal>
  );
}

export default EnquiryModal;