import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import EnquiryModal from '../modal/EnquiryModal';
import Button from 'react-bootstrap/Button';

import aboutMeBg from '../../reassets/backgrounds/bg-about-me.jpg';



const ActionBanner = () => {

    const [show, setShow] = useState(false);
    const toggleModal = () => {
        show === true ? setShow(false) : setShow(true);
    }
    return (
        <>
            <section className='bottom-wrapper' style={{ background: `url(${aboutMeBg})` }}>
                <div className='container'>
                    <div className="row">
                        <div className="bottom-box" data-aos="fade-up">
                            {/* <h5 className="sub-title">So What's Next?</h5> */}
                            <h2 className="the-title">
                                <Link onClick={toggleModal} to="#">
                                    Are You Ready? <span>Let’s Work!</span>
                                </Link>
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
            <EnquiryModal showModal={show} handleToggleModal={toggleModal} />

        </>
    )
}

export default ActionBanner