import React, { useState } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import 'react-photo-view/dist/react-photo-view.css';




const ProjectGallery = (props) => {


    return (
        <>

            <section className='gallery-wrapper py-5'>
                <div className='container mt-4'>
                    <div className='row text-center'>
                        {
                            props.mediaOnly == false ?
                                <>
                                    <p className='text-white'>Picture Perfect</p>
                                    <h1> Explore Hude <br /> Production <span>Projects</span> </h1>
                                    <p className='text-white'>Hude Production runs wide and deep. Across many markets, geographies & typologies, our team members</p>
                                </>
                                :
                                <>
                                </>
                        }

                        <div className='col-md-12'>
                            <PhotoProvider>
                                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 3, 750: 6, 900: 4 }}>
                                    <Masonry columnsCount={3} gutter="5px">
                                        {props.images && props.images.map((item, index) => (
                                            <div key={index}>
                                                <PhotoView src={item}>
                                                    <img src={item} alt="" key={index} style={{ display: 'block', padding: '5px', width: '100%' }} />
                                                </PhotoView>
                                            </div>
                                        ))}
                                    </Masonry>
                                </ResponsiveMasonry>

                            </PhotoProvider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProjectGallery