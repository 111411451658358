import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import PhotoAlbum from "react-photo-album";
import { InstagramEmbed } from 'react-social-media-embed';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button } from 'react-bootstrap';

import editingCoverPhoto from '../../reassets/Images/editing/1.jpg';
import fashionImg3 from '../../reassets/Images/fashion/5.webp';
import weddingImg from '../../reassets/Images/wedding/10.webp';
import preWeddingImg from '../../reassets/Images/pre-wedding/1.webp';
import cameraImg from '../../reassets/Images/camera.jpg';
import productImg from '../../reassets/Images/product.jpg';
import fashionImg from '../../reassets/Images/fashion.jpg';



import EnquiryModal from '../modal/EnquiryModal';

const BestCollections = () => {

    const [show, setShow] = useState(false);
    const toggleModal = () => {
        show === true ? setShow(false) : setShow(true);
    }

    let settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        className: "center",
        centerPadding: 30,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    };

    const hudeServices = [
        {
            pageLink: 'services/e-commerce',
            imgSrc: productImg,
            title: 'E-commerce',
            btnText: 'Enquire Now'
        },
        {
            pageLink: 'services/pre-wedding',
            imgSrc: preWeddingImg,
            title: 'Pre Wedding',
            btnText: 'Enquire Now'
        },
        {
            pageLink: 'services/wedding',
            imgSrc: weddingImg,
            title: 'Wedding',
            btnText: 'Enquire Now'
        },
        {
            pageLink: 'services/fashion',
            imgSrc: fashionImg,
            title: 'Fashion',
            btnText: 'Enquire Now'
        },
        {
            pageLink: 'services/product',
            imgSrc: fashionImg3,
            title: 'Product',
            btnText: 'Enquire Now'
        },
        {
            pageLink: 'services/editing',
            imgSrc: cameraImg,
            title: 'Editing',
            btnText: 'Enquire Now'
        },

    ]

    return (
        <>
            <section className='service-wrapper py-3 bg-dark' data-bs-theme="dark">
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <p className='text-white cursive'>Our best collections</p>
                            <h1>What we <span>do</span> </h1>
                            <p className='text-white'>At Hude Production, we specialize in producing captivating imagery that resonates with emotion, creativity, and authenticity. Whether it’s a corporate project, a special event, or a creative campaign, we are committed to delivering exceptional results that exceed expectations.</p>
                        </div>
                        <div className='col-md-9'>
                            <Slider {...settings}>
                                {
                                    hudeServices.map((service, index) => {
                                        return (
                                            <>
                                                <Link key={index} to={service.pageLink} className='card' style={{ marginRight: "10px" }}>
                                                    <img src={service.imgSrc} />
                                                    <div className='card-content'>
                                                        <h5>{service.title}</h5>
                                                        <p>Get Quote <span></span></p>
                                                    </div>
                                                </Link>
                                            </>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>

            </section>

            <EnquiryModal showModal={show} handleToggleModal={toggleModal} />

        </>
    )
}

export default BestCollections