import React from 'react';

import photoImg from '../../reassets/Images/service/service_icon1.png';
import movieImg from '../../reassets/Images/service/service_icon3.png';
import editingImg from '../../reassets/Images/service/service_icon4.png';
import socialMediaImg from '../../reassets/Images/service/service_icon5.png';



const Features = () => {

    const services = [
        {
            id:1,
            title: "PHOTOGRAPHY/VIDEOGRAPHY",
            description: " Our talented team specializes in capturing unforgettable moments and creating visually stunning films, from cinematic productions to high-quality photos and videos.",
            imgUrl: photoImg,
        },
        {
            id:2,
            title: "MOVIES",
            description: "At Hude Productions, we bring stories to life with exceptional movie-making services tailored to capture and convey powerful narratives.",
            imgUrl: movieImg,
        },
        {
            id:3,
            title: "EDITING",
            description: "From capturing memorable moments to expertly editing photos and videos, our skilled team is dedicated to creating captivating visuals that tell your story with precision and artistry.",
            imgUrl: editingImg,
        },
        {
            id:4,
            title: "SOCIAL MEDIA",
            description: "We also provide tailored social media solutions, including eye-catching thumbnail creation and post design to boost your online presence.",
            imgUrl: socialMediaImg,
        }
    ]

    return (
        <>
            <section className='our-process mt-5'>
                <div className='container mb-4 pt-4'>
                    <div className='row'>
                        {
                            services.map((service,index) => (
                                <>
                                    <div key={service.id} className='col-md-6'>
                                        <div className='our-process-box'>
                                            <img src={service.imgUrl} alt="" />
                                            <div>
                                                <h5 className='text-dark-light'>{service.title}</h5>
                                                <p className='text-white'>{service.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))
                        }

                    </div>
                </div>
            </section>
        </>
    )
}

export default Features;